<template>
    <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
            <v-btn small class="primary" v-bind="attrs" v-on="on">Embed codes</v-btn>
        </template>
        <v-list>
            <v-list-item>
                <copy-to-clipboard :textToCopy="embedCode(video, 'fixed')" :isTextButton="true" buttonName="Fixed size"></copy-to-clipboard>
            </v-list-item>
            <v-list-item>
                <copy-to-clipboard :textToCopy="embedCode(video, 'responsive')" :isTextButton="true" buttonName="Responsive"></copy-to-clipboard>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import vimeo from '@/mixins/vimeo'

const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    name: 'VimeoEmbedCodeBtn',
    components: { CopyToClipboard },
    mixins: [vimeo],
    props: {
        video: { type: Object, required: true }
    }
}
</script>
